import axios from 'axios';
import {
  AUTHENTICATE,
  DEAUTHENTICATE,
  USER,
  SWEETALERT,
  SUBMITTING,
  LOADING,
  MODALMEMBER,
  SETCART,
  OPENCART,
  SETEXPIREDPOINT,
  SETSUMPOINT,
} from '../types';
import { getCookie, setCookie, removeCookie, setEncryptedCookie, getDecryptedCookie } from '/utils/cookie';
import getConfig from 'next/config';
import Router from 'next/router';
import { fromJS, Map } from 'immutable';
import checkEmptyObject from '/utils/checkEmptyObject';
import getUUID from '/utils/getUUID';
import getUrlParameter from '/utils/getUrlParameter';
import validateAndFilter from '/utils/validateAndFilter';
import onLoginError from './_onLoginError';
import React from 'react';

const { publicRuntimeConfig } = getConfig();

const authenticate = ({ email, password, fb_id, fb_token, not_close_popup, check_if_registered, locale = 'en' }) => {
  return async (dispatch, getState) => {
    const subdomain = getState().subdomain;
    let token_cookie = 'token';
    let endpoint = `${publicRuntimeConfig.API_PATH}/user`;
    if (subdomain === 'business') {
      token_cookie = 'token_business';
      endpoint = `${publicRuntimeConfig.API_PATH}/business/login`;
    }

    dispatch({ type: SUBMITTING, payload: true });
    dispatch({ type: LOADING, payload: true });

    const onLoginSuccess = res => {
      dispatch({ type: SUBMITTING, payload: false });
      dispatch({ type: LOADING, payload: false });
      if (check_if_registered && !res.data) {
        return res;
      }
      setCookie(token_cookie, res.data);
      dispatch({
        type: AUTHENTICATE,
        payload: res.data,
      });
      dispatch({ type: SUBMITTING, payload: false });
      if (!not_close_popup) dispatch({ type: MODALMEMBER, payload: { show: false } });

      dispatch(getUser({ token: res.data }));
      dispatch(getCart(res.data, locale, true));

      return res;
    };

    try {
      let form = new FormData();
      if (fb_token && fb_id) {
        // login for FB
        endpoint = `${publicRuntimeConfig.API_PATH}/user/facebook/login`;
        form.append('fb_token', fb_token);
        form.append('fb_id', fb_id);
        if (email) {
          form.append('email', email);
        }
        if (check_if_registered) {
          form.append('check_if_registered', check_if_registered);
        }
      } else {
        form.append('email', email);
        form.append('password', password);
      }
      dispatch({ type: LOADING, payload: true });
      const response = await axios.post(endpoint, form);
      return onLoginSuccess(response);
    } catch (err) {
      console.log(err);
      return onLoginError(err, dispatch);
    }
  };
};

const reauthenticate = (token, user) => {
  return dispatch => {
    dispatch({ type: AUTHENTICATE, payload: token });
    dispatch({ type: USER, payload: user });
  };
};

const registerSucceded = (dispatch, email, password, locale = 'en') => {
  dispatch({
    type: SWEETALERT,
    payload: {
      show: true,
      title: 'Hurray! You are successfully registered. Click okay to login to Trazy.',
      type: 'success',
      onConfirm: () => {
        dispatch({
          type: MODALMEMBER,
          payload: {
            show: true,
            type: 'welcome',
          },
        });
        dispatch(authenticate({ email, password, not_close_popup: true, locale }));
      },
    },
  });
  dispatch({ type: SUBMITTING, payload: false });
  dispatch({ type: LOADING, payload: false });
};
const registerFailed = (dispatch, err) => {
  if (err?.response?.data?.detail) {
    const message = err.response.data.detail;
    if (message === 'The email already exists.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          show: true,
          title: 'This email address is already registered.\nPlease log in.',
        },
      });
    } else if (message === 'Account has not been approved yet.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'Oops! Your account has not been approved yet.',
          show: true,
        },
      });
    } else if (message === 'Account is not approved.') {
      dispatch({
        type: SWEETALERT,
        payload: {
          title: 'Oops! Your account is not approved.',
          show: true,
        },
      });
    } else {
      dispatch({
        type: SWEETALERT,
        payload: {
          show: true,
          title: 'User registration failed.\nPlease wait a moment and try again.',
        },
      });
    }
  } else {
    dispatch({
      type: SWEETALERT,
      payload: {
        show: true,
        title: 'User registration failed.\nPlease wait a moment and try again.',
      },
    });
  }
  dispatch({ type: SUBMITTING, payload: false });
  dispatch({ type: LOADING, payload: false });
};
const integratefacebook = ({ access_token, fb_id, fb_token }, afterSuccess, afterFail) => {
  return dispatch => {
    dispatch({ type: SUBMITTING, payload: true });
    dispatch({ type: LOADING, payload: true });
    const form = new FormData();
    form.append('fb_id', fb_id);
    form.append('fb_token', fb_token);
    axios
      .post(`${publicRuntimeConfig.API_PATH}/user/facebook`, form, {
        headers: {
          Authorization: 'bearer ' + access_token,
        },
      })
      .then(res => {
        console.log(res);
        if (afterSuccess) {
          afterSuccess();
        }
        dispatch({ type: SUBMITTING, payload: false });
        dispatch({ type: LOADING, payload: false });
      })
      .catch(err => {
        console.log(err.response);
        if (afterFail) {
          afterFail(dispatch, err);
        }
        dispatch({ type: SUBMITTING, payload: false });
        dispatch({ type: LOADING, payload: false });
      });
  };
};
const register = ({ first_name, last_name, email, password, birth, gender, fb_token, fb_id, locale = 'en' }) => {
  return dispatch => {
    dispatch({ type: SUBMITTING, payload: true });
    dispatch({ type: LOADING, payload: true });
    const form = new FormData();
    form.append('first_name', first_name);
    form.append('last_name', last_name);
    form.append('email', email);
    form.append('password', password);
    form.append('birth', birth);
    if (gender) form.append('gender', gender);
    if (fb_token) form.append('fb_token', fb_token);
    if (fb_id) form.append('fb_id', fb_id);
    axios
      .put(`${publicRuntimeConfig.API_PATH}/user`, form)
      .then(() => {
        registerSucceded(dispatch, email, password, locale);
      })
      .catch(err => {
        registerFailed(dispatch, err);
      });
  };
};

const logout = (dispatch, ctx, subdomain) => {
  if (subdomain === 'business') {
    removeCookie('token_business');
    removeCookie('user_business');
    removeCookie('hash_business');
  } else {
    removeCookie('token');
    removeCookie('user');
    removeCookie('hash');
  }

  window.safeStorage.removeItem('cart');
  dispatch({ type: SETCART, payload: Map({}) });
  dispatch({ type: DEAUTHENTICATE });
  dispatch({ type: OPENCART, payload: false });
  dispatch({ type: SETEXPIREDPOINT, payload: 0 });
  dispatch({ type: SETSUMPOINT, payload: 0 });
  if (ctx && ctx.res) {
    ctx.res.writeHead(302, {
      Location: `/`,
    });
    ctx.res.end();
  } else {
    Router.push(`/`);
  }
};
const deauthenticate = (token, ctx) => {
  return (dispatch, getState) => {
    const subdomain = getState().subdomain;
    let endpoint = `${publicRuntimeConfig.API_PATH}/user/logout`;
    if (subdomain === 'business') {
      endpoint = `${publicRuntimeConfig.API_PATH}/business/logout`;
    }
    axios
      .post(
        endpoint,
        {},
        {
          headers: {
            Authorization: 'bearer ' + token,
          },
        },
      )
      .then(() => {
        logout(dispatch, ctx, subdomain);
      })
      .catch(() => {
        logout(dispatch, ctx, subdomain);
      });
  };
};

const clearUserData = (ctx) => {
  return (dispatch) => {
    removeCookie('token');
    removeCookie('user');
    removeCookie('hash');

    window.safeStorage.removeItem('cart');
    dispatch({ type: SETCART, payload: Map({}) });
    dispatch({ type: DEAUTHENTICATE });
    dispatch({ type: OPENCART, payload: false });
    dispatch({ type: SETEXPIREDPOINT, payload: 0 });
    dispatch({ type: SETSUMPOINT, payload: 0 });
    if (ctx && ctx.res) {
      ctx.res.writeHead(302, {
        Location: `/`,
      });
      ctx.res.end();
    } else {
      Router.push(`/`);
    }
  }
};

const getUser = ({ token }) => {
  return async (dispatch, getState) => {
    const subdomain = getState().subdomain;
    let endpoint = publicRuntimeConfig.API_PATH + '/user/me';
    let user_cookie = 'user';
    if (subdomain === 'business') {
      endpoint = publicRuntimeConfig.API_PATH + '/business/user/me';
      user_cookie = 'user_business';
    }

    return await axios
      .get(endpoint, {
        headers: {
          Authorization: 'bearer ' + token,
        },
      })
      .then(res => {
        setEncryptedCookie(user_cookie, res.data);
        dispatch({ type: USER, payload: res.data });
        dispatch({ type: SETSUMPOINT, payload: res.data?.point_sum || 0 });

        const redirect = getUrlParameter('redirect');
        if (redirect) {
          Router.push(redirect);
        }

        return res;
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          if (response.data.detail === 'Invalid token.') {
            logout(dispatch);
          }
        }
        return false;
      });
  };
};

const getCart = (token, locale = 'en', afterLogin = false) => {
  return async (dispatch, getState) => {
    let cart = Map({});
    let properties = null;
    let cart_for_view = Map({});
    let hash = '';
    let hash_key = 'hash';
    let cart_key = 'cart';
    let cart_from_storage = window.safeStorage.getItem('cart');
    let endpoint_cart = publicRuntimeConfig.API_PATH + '/user/me/cart';
    const subdomain = getState().subdomain;
    if (subdomain === 'business') {
      cart_key = 'cart_business';
      cart_from_storage = window.safeStorage.getItem('cart_business');
      hash_key = 'hash_business';
      endpoint_cart = publicRuntimeConfig.API_PATH + '/business/user/me/cart';
    }
    endpoint_cart += `?locale=${locale}`;
    // 1. get cart data from local storage for non members
    if (cart_from_storage) {
      const cart_json = JSON.parse(cart_from_storage);
      if (!checkEmptyObject(cart_json)) {
        // if cookie cart has item, assign to cart variable
        cart = fromJS(cart_json);
        if (subdomain !== 'business') {
          const decrypted_properties = getDecryptedCookie('properties');
          if (decrypted_properties) {
            properties = JSON.parse(decrypted_properties);
          }
        }
        hash = getCookie(hash_key);
      }
    }
    // 2. if user has no token, then return local cart and hash
    if (!token) {
      if (cart.size) {
        const result = await validateAndFilter(cart, null, locale);
        cart = result.cart;
        cart_for_view = result.cart_for_view;
        if (!hash) {
          hash = getUUID();
          setCookie(hash_key, hash);
        }
      }
      dispatch({ type: SETCART, payload: cart });
      return {
        cart,
        hash,
        cart_for_view,
      };
    }
    // 3. if user is logged in, get cart data and merge with local data
    const get_cart_result = await axios
      .get(endpoint_cart, {
        headers: {
          Authorization: 'bearer ' + token,
        },
      })
      .then(async res => {
        const data = res.data;
        if (data && checkEmptyObject(data)) {
          // if user has empty cart
          if (!hash) hash = getUUID(); // if has no hash, create new hash with UUID
          const result = await validateAndFilter(cart, token, locale);
          cart = result.cart;
          cart_for_view = result.cart_for_view;
          if (cart.size) {
            await dispatch(updateCart(token, cart, properties, hash, locale));
          } else {
            dispatch({ type: SETCART, payload: cart });
          }
          window.safeStorage.removeItem(cart_key);
          if (subdomain !== 'business') {
            removeCookie('properties');
          }
          if (hash !== getCookie(hash_key)) {
            setCookie(hash_key, hash);
          }
          return {
            cart,
            cart_for_view,
            hash,
          };
        }
        let contents = data.contents || {};
        // merge local & db property
        if (properties) {
          const db_properties = data.properties || {};
          Object.entries(db_properties).forEach(([key, value], i) => {
            if (key === 'required_information_map') {
              if (properties[key]) {
                Object.entries(value).forEach(([req_key, req_value], i) => {
                  if (!properties[key][req_key]) {
                    properties[key][req_key] = req_value;
                  }
                })
              } else {
                properties[key] = value;
              }
            } else if (key === 'phone_available') {
              if (properties[key] && JSON.stringify(properties[key]) === JSON.stringify([0, 0, 0, 0, 0])) {
                properties[key] = value;
              }
            } else {
              if (!properties[key]) {
                properties[key] = value;
              }
            }
          });
        } else {
          properties = data.properties || {};
        }
        if (data.hash) hash = data.hash;
        // 4. merge local cart and fetched cart
        cart = fromJS(contents).mergeWith((prev, next) => {
          if (next.getIn(['cart_order']) >= prev.getIn(['cart_order'])) {
            const updated_item = next.setIn(['cart_order'], prev.getIn(['cart_order']));
            return updated_item;
          } else {
            return prev;
          }
        }, cart);
        const result = await validateAndFilter(cart, token, locale);
        cart = result.cart;
        cart_for_view = result.cart_for_view;
        if (cart.size) {
          if (!hash) hash = getUUID();
          dispatch(updateCart(token, cart, properties, hash, locale));
        }
        // 5. delete localstorage after merge
        window.safeStorage.removeItem(cart_key);
        if (subdomain !== 'business') {
          removeCookie('properties');
        }
        if (hash !== getCookie(hash_key)) {
          setCookie(hash_key, hash);
        }
        return {
          cart,
          cart_for_view,
          hash,
          properties,
        };
      })
      .catch(err => {
        const response = err.response;
        if (response) {
          if (response.data.detail === 'Invalid token.') {
            logout(dispatch);
          }
        }
        return {
          cart,
          hash,
        };
      });
    const cur_path = location.pathname;
    if (afterLogin && cur_path === '/experience/book' && subdomain !== 'business') {
      location.reload();
    }
    return get_cart_result;
  };
};
const updateCart = (token, contents, properties = {}, hash, locale = 'en') => {
  return async (dispatch, getState) => {
    try {
      let params = {};
      let payload = {};
      if (contents) {
        let _contents = contents.toJS();
        for (let [key, item] of Object.entries(_contents)) {
          if (getCookie(`spckey${item.activity_id}`)) {
            _contents[key].spckey = getCookie(`spckey${item.activity_id}`);
          }
        }
        params = {
          contents: _contents,
          properties,
          hash,
        };
        payload = contents;
      }
      let endpoint_cart = publicRuntimeConfig.API_PATH + '/user/me/cart';
      const subdomain = getState().subdomain;
      if (subdomain === 'business') {
        endpoint_cart = publicRuntimeConfig.API_PATH + '/business/user/me/cart';
      }
      endpoint_cart += `?locale=${locale}`;
      const response = await axios.post(endpoint_cart, params, {
        headers: {
          Authorization: 'bearer ' + token,
        },
      });
      dispatch({ type: SETCART, payload: fromJS(payload) });
      return response;
    } catch (error) {
      return error;
    }
  };
};

const setSumPoint = payload => {
  return dispatch => {
    dispatch({ type: SETSUMPOINT, payload });
  };
};

const setExpiredPoint = payload => {
  return dispatch => {
    dispatch({ type: SETEXPIREDPOINT, payload });
  };
};

export default {
  setSumPoint,
  setExpiredPoint,
  register,
  authenticate,
  reauthenticate,
  deauthenticate,
  clearUserData,
  integratefacebook,
  getUser,
  getCart,
  updateCart,
};
