import { Component } from 'react';
import styled from 'styled-components';
import Button from '/components/ui/Button';
import Or from './Or';
import OtherLink from './OtherLink';
import theme from '/style/theme';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import ButtonGroup from '/components/ui/ButtonGroup';
import ButtonBack from '/components/ui/ButtonBack';
import Textbox from '/components/ui/Textbox';
import InputGroup from '/components/ui/InputGroup';
import RadioButton from '/components/ui/RadioButton';
import RadioButtonGroup from '/components/ui/RadioButtonGroup';
import Link from 'next/link';
import { connect } from 'react-redux';
import actions from '/store/actions';
import getConfig from 'next/config';
import checkEmailRule from '/utils/checkEmailRule';
import Checkbox from '/components/ui/Checkbox';
import MobiscrollBirth from 'components/ui/MobiscrollBirth';
import withTranslation from 'next-translate/withTranslation';
import Trans from 'next-translate/Trans';

const { publicRuntimeConfig } = getConfig();
const Wrapper = styled.div``;
const Step1 = styled.div`
  margin-top: -75px;
  @media ${theme.sm} {
    margin-top: 0;
  }
`;
const Step2 = styled.div`
  .back-button {
    display: none;
    @media ${theme.sm} {
      display: block;
    }
  }
`;
const Half = styled.div`
  width: 50%;
  padding: 0 3px;
  float: left;
`;
const Label = styled.label`
  float: left;
  height: 50px;
  line-height: 50px;
  font-weight: ${theme.normal};
`;
const FormGroup = styled.div`
  padding: 30px 20px;
  background-color: #fff;
  @media ${theme.sm} {
    padding: 60px 75px 30px 72px;
  }
`;
const SubmitGroup = styled.div`
  margin: 0;
  padding: 20px;
  background-color: ${theme.light_gy1};
  border-top: 1px solid ${theme.line_gy};
  @media ${theme.sm} {
    padding: 20px 70px;
  }
`;
const SubmitDescription = styled.div`
  color: ${theme.mid_gy};
  a {
    color: ${theme.brand};
  }
  label {
    padding-left: 23px;
    height: auto;
    .label {
      font-size: 14px;
      line-height: 1.54;
      &:before {
        top: 50%;
        transform: translateY(-50%);
        margin-top: -2px;
      }
    }
  }
`;
const FloatRight = styled.div`
  width: 63px;
  padding: 0;
  float: right;
  .mbsc-select-input[type='text'] {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    background-image: none;
  }
`;
class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      confirm_email: '',
      password: '',
      confirm_password: '',
      birth: null,
      gender: 'M',
      facebook_email: false,
      fb_token: null,
      fb_id: null,
      beforeSubmit: false,
      years: [],
      agree_policy: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleOnSet = this.handleOnSet.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    const { changeSweetAlert, register } = this.props;
    const {
      first_name,
      last_name,
      email,
      password,
      confirm_email,
      confirm_password,
      birth,
      gender,
      fb_token,
      fb_id,
      agree_policy,
    } = this.state;
    const { t } = this.props.i18n;
    if (!first_name || !last_name) {
      changeSweetAlert(t('popup.validation.empty-all'));
      return;
    }
    if (!email) {
      changeSweetAlert(t('popup.validation.empty-email'));
      return;
    }
    if (!checkEmailRule(email)) {
      changeSweetAlert(t('popup.validation.invalid-email'));
      return;
    }
    if (!confirm_email) {
      changeSweetAlert(t('popup.validation.empty-email'));
      return;
    }
    if (email !== confirm_email) {
      changeSweetAlert(t('popup.validation.invalid-confirm-email'));
      return;
    }
    if (password.length < 6) {
      changeSweetAlert(
        t('popup.validation.short-password')
      );
      return;
    }
    if (password != confirm_password) {
      changeSweetAlert(t('popup.validation.invalid-confirm-password'));
      return;
    }
    /*const yearRule = /^\d{4}$/;
    if (!yearRule.test(parseInt(birth))) {
      changeSweetAlert(t('popup.validation.invalid-yob'));
      return;
    }*/
    if (!agree_policy) {
      changeSweetAlert(
        t('popup.validation.agree-unchecked')
      );
      return;
    }
    const { lang } = this.props.i18n;
    register({
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      email: email.trim(),
      password: password,
      // birth: birth + '-01-01',
      // gender: gender,
      birth: null,
      gender: null,
      fb_token,
      fb_id,
      locale: lang,
    });
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  handleOnSet(e) {
    this.setState({
      birth: e.valueText,
    });
  }
  resetForm() {
    this.setState({
      first_name: '',
      last_name: '',
      email: '',
      confirm_email: '',
      facebook_email: false,
      access_token: null,
      password: '',
      birth: null,
      gender: 'M',
      beforeSubmit: false,
      show: false,
      message: '',
      type: 'error',
      agree_policy: false,
    });
  }
  componentDidMount() {
    const years = [
      {
        text: '',
        value: '',
      },
    ];
    const thisYear = new Date().getFullYear();
    for (let i = 1940; i <= thisYear; i++) {
      years.push({
        text: i,
        value: i,
      });
    }
    this.setState({
      years,
    });
  }
  responseFacebook(res) {
    const { lang } = this.props.i18n;
    this.props
      .authenticate({
        fb_token: res.accessToken,
        fb_id: res.id,
        email: res.email,
        check_if_registered: true,
        locale: lang,
      })
      .then(response => {
        if (response.data) {
          // if login succeed, turn off the member modal
          this.props.changeModalMember({ show: false });
          return;
        }
        if (res.email) {
          // if facebook email is shown public
          this.setState({
            facebook_email: true,
            first_name: res.first_name,
            last_name: res.last_name,
            email: res.email,
            confirm_email: res.email,
            fb_token: res.accessToken,
            fb_id: res.id,
          });
        } else {
          // if facebook email is blocked
          this.props.changeSweetAlert(
            'Facebook Sign up failed. You can continue signing up by giving Trazy permission to access to your email address. or Signup with your e-mail.'
          );
          return;
        }
        this.props.changeModalMember({
          show: true,
          type: 'signup2',
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  render() {
    const { type, changeModalMember, uistatus, device } = this.props;
    const {
      first_name,
      last_name,
      email,
      confirm_email,
      password,
      confirm_password,
      birth,
      gender,
      facebook_email,
      years,
      agree_policy,
    } = this.state;
    const { t } = this.props.i18n;
    return (
      <Wrapper>
        {type === 'signup' ? (
          <Step1>
            {device !== 'app' ? (
              <>
                <FacebookLogin
                  appId={publicRuntimeConfig.FB_APP_ID}
                  callback={this.responseFacebook}
                  fields="first_name,last_name,email"
                  render={renderProps => (
                    <Button full facebook onClick={renderProps.onClick}>
                      {t('popup.signup-with-fb')}
                    </Button>
                  )}
                />
                <Or />
              </>
            ) : null}
            <Button
              full
              gradient_mint
              onClick={() => {
                changeModalMember({
                  show: true,
                  type: 'signup2',
                });
              }}
            >
              {t('popup.with-email')}
            </Button>
            <OtherLink>
              <Trans
                i18nKey="common:popup.already-member"
                components={[
                  <strong
                    onClick={() => {
                      this.resetForm();
                      changeModalMember({
                        show: true,
                        type: 'login',
                      });
                    }}
                  >
                  </strong>
                ]}
              >
              </Trans>
            </OtherLink>
          </Step1>
        ) : (
          <Step2>
            <ButtonBack
              onClick={() => {
                this.resetForm();
                changeModalMember({
                  show: true,
                  type: 'signup',
                });
              }}
              className="back-button"
            />
            <form
              onSubmit={this.handleSubmit}
              noValidate
              className="form-signup"
            >
              <FormGroup>
                <InputGroup multi_column>
                  <Half>
                    <Textbox
                      id="first_name"
                      name="first_name"
                      placeholder={t('popup.form.first-name')}
                      value={first_name}
                      onChange={this.handleInputChange}
                      half
                      mint
                    />
                  </Half>
                  <Half>
                    <Textbox
                      id="last_name"
                      name="last_name"
                      placeholder={t('popup.form.last-name')}
                      value={last_name}
                      onChange={this.handleInputChange}
                      half
                      mint
                    />
                  </Half>
                </InputGroup>
                <InputGroup>
                  <Textbox
                    id="email"
                    name="email"
                    placeholder={t('popup.form.email')}
                    value={email}
                    onChange={this.handleInputChange}
                    autoComplete="email"
                    readOnly={facebook_email}
                    mint
                  />
                </InputGroup>
                <InputGroup>
                  <Textbox
                    id="confirm_email"
                    name="confirm_email"
                    placeholder={t('popup.form.confirm-email')}
                    value={confirm_email}
                    onChange={this.handleInputChange}
                    autoComplete="email"
                    readOnly={facebook_email}
                    mint
                  />
                </InputGroup>
                <InputGroup>
                  <Textbox
                    id="password"
                    name="password"
                    placeholder={t('popup.form.password')}
                    autoComplete="current-password"
                    value={password}
                    onChange={this.handleInputChange}
                    mint
                    can_switch_type
                  />
                </InputGroup>
                <InputGroup>
                  <Textbox
                    id="confirm_password"
                    name="confirm_password"
                    placeholder={t('popup.form.confirm-password')}
                    onChange={this.handleInputChange}
                    value={confirm_password}
                    mint
                    can_switch_type
                  />
                </InputGroup>
                {/*<InputGroup>
                  <Label htmlFor="birth">{t('popup.form.birth-year')}</Label>
                  <FloatRight>
                    <MobiscrollBirth
                      birth={birth}
                      years={years}
                      handleOnSet={this.handleOnSet}
                    />
                  </FloatRight>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="">{t('popup.form.gender')}</Label>
                  <RadioButtonGroup style={{ float: 'right' }}>
                    <RadioButton
                      mint
                      id="male"
                      name="gender"
                      value="M"
                      checked={gender === 'M'}
                      onChange={this.handleInputChange}
                    >
                      {t('popup.form.male')}
                    </RadioButton>
                    <RadioButton
                      mint
                      id="female"
                      name="gender"
                      value="F"
                      checked={gender === 'F'}
                      onChange={this.handleInputChange}
                    >
                      {t('popup.form.female')}
                    </RadioButton>
                  </RadioButtonGroup>
                </InputGroup>*/}
              </FormGroup>
              <SubmitGroup>
                <SubmitDescription>
                  <Checkbox
                    id="agree_policy"
                    name="agree_policy"
                    value={agree_policy}
                    onChange={this.handleInputChange}
                    small
                  >
                    <Trans
                      i18nKey="common:popup.signup-agree"
                      components={{
                        br: <br />,
                        tos_link: <Link href="/main/footer/termsofservice">
                          <a target="_blank" rel="noreferrer noopener">{t('popup.tos')}</a>
                        </Link>,
                        pp_link: <Link href="/main/footer/privacypolicy">
                          <a target="_blank" rel="noreferrer noopener">{t('popup.privacy-policy')}</a>
                        </Link>,
                      }}
                    >
                    </Trans>
                  </Checkbox>
                </SubmitDescription>
                <ButtonGroup>
                  <Button submit full mint disabled={uistatus.submitting} style={{ textTransform: "uppercase" }}>
                    {t('signup')}
                  </Button>
                </ButtonGroup>
              </SubmitGroup>
            </form>
          </Step2>
        )}
      </Wrapper>
    );
  }
}

export default connect(state => state, actions)(withTranslation(Signup, 'common'));
