import axios from 'axios';
import getConfig from 'next/config';
import { getCookie, getDecryptedCookie, getNoTrackerPermissionCookie } from './cookie';
import getUUID from './getUUID';
const { publicRuntimeConfig } = getConfig();


//export const GA_TRACKING_ID = 'UA-34795783-1';
export const GA_OPTIMIZE_ID = 'GTM-PVLQ4JR';
export const GA_AD_ID = 'AW-995467235';
export const GA4_TRACKING_ID = 'G-V0YMKNXDBN';

export const enable_tracking = () => {
  return window.gtag || window.fbq;
};

export const getEventId = () => {
  return getUUID().replace(/-/g, '');
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (authentication, dimension, meta_title) => {
  if (getNoTrackerPermissionCookie()) {
    return;
  }
  if (window.gtag) {
    let params = {
      page_location: window.location.href,
      page_title: meta_title ? meta_title : window.document.title,
      ...dimension,
    };
    window.gtag('config', `${GA_AD_ID}`);
    window.gtag('event', 'conversion', {'send_to': `${GA_AD_ID}/hzhhCK6j2bcBEOO_1toD`});
    window.gtag('config', `${GA4_TRACKING_ID}`, params);
  }
  if (window.fbq) {
    const event_id = getEventId();
    const track_params = { eventID: event_id };
    if (authentication?.user?.user_id) track_params['external_id'] = authentication.user.user_id;
    window.fbq('track', 'PageView', {}, track_params);
    sendConversion({
      event_name: 'PageView',
      params: null,
      additional_params: {
        event_id
      },
      token: authentication.token
    });
  }
};

export const viewContent = (content_type, activity, authentication) => {
  if (getNoTrackerPermissionCookie()) {
    return;
  }
  if (window.fbq) {
    let params = {
      content_type: content_type,
      content_ids: activity.tour_idx,
      content_name: activity.title_en ? activity.title_en : activity.title,
      value: activity.price_usd > 0 ? activity.price_usd : 1,
      currency: 'USD',
    };
    if (content_type === 'destination') {
      params = {
        ...params,
        region: activity.city_name_en,
        city: activity.city_name_en,
        country: activity.country_name_en
      }
      if (activity.min_date) {
        params.travel_start = activity.min_date;
      }
      if (activity.max_date) {
        params.travel_end = activity.max_date;
      }
    }
    const event_id = getEventId();
    const track_params = { eventID: event_id };
    if (authentication?.user?.user_id) track_params['external_id'] = authentication.user.user_id;
    window.fbq('track', 'ViewContent', params, track_params);
    sendConversion({
      event_name: 'ViewContent',
      params,
      additional_params: {
        event_id
      },
      token: authentication.token
    });
  }
};

export const purchase = (transaction, authentication) => {
  if (getNoTrackerPermissionCookie()) {
    return;
  }
  if (window.gtag) {
    let items = [];
    transaction.items.map((item, i) => {
      item.participant_list.map((participant, j) => {
        items.push({
          'item_id': item.activity_id,
          'item_name': item.title + ' - ' + item.option_name,
          'item_variant': participant.participant_type,
          'quantity': `${participant.participant}`,
          'price': `${participant.item_price}`,
        });
      });
    });
    // Ecommerce
    window.gtag('event', 'purchase', {
      'transaction_id': transaction.cart_idx,
      'affiliation': '',
      'value': `${transaction.final_price}`,
      'currency': 'USD',
      'tax': 0,
      'shipping': 0,
      'items': items
    });
    // Event snippet for 결제추적 conversion page
    window.gtag('event', 'conversion', {
      'send_to': 'AW-995467235/_5juCIXFrAsQ47_W2gM',
      'value': `${transaction.final_price}`,
      'currency': 'USD',
      'transaction_id': ''
    });
  }
  if (window.fbq) {
    let content_ids = [];
    let min_date = 0;
    let max_date = 0;
    transaction.items.map((item, i) => {
      if (content_ids.indexOf(item.activity_id) < 0) {
        content_ids.push(item.activity_id + '');
        if (i == 0 || item.reserve_date <= min_date) {
          min_date = item.reserve_date;
        }
        if (item.reserve_date >= max_date) {
          max_date = item.reserve_date;
        }
      }
    });
    const params = {
      content_type: 'destination',
      content_ids: content_ids,
      travel_start: min_date.substr(0,4)+'-'+min_date.substr(4, 2)+'-'+min_date.substr(6, 2),
      travel_end: max_date.substr(0,4)+'-'+max_date.substr(4, 2)+'-'+max_date.substr(6, 2),
      price: `${transaction.final_price}`,
      value: `${transaction.final_price}`,
      currency: 'USD'
    };
    const event_id = getEventId();
    const track_params = { eventID: event_id };
    if (authentication?.user?.user_id) track_params['external_id'] = authentication.user.user_id;
    window.fbq('track', 'Purchase', params, track_params);
    sendConversion({
      event_name: 'Purchase',
      params,
      additional_params: {
        cart_idx: transaction.cart_idx,
        event_id
      },
      token: authentication.token
    });
  }
};

export const addToCart = (value, url) => {
  if (getNoTrackerPermissionCookie()) {
    return;
  }
  if (window.gtag) {
    if (typeof (value) == 'undefined') {
      value = 8.0;
    }
    let callback = function () {
      if (typeof (url) != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-995467235/259ACJnAyrsBEOO_1toD',
      'value': value,
      'currency': 'USD',
      'event_callback': callback
    });
  }
  return false;
};

export const initiateCheckout = (content_ids, num_items, price, authentication) => {
  if (getNoTrackerPermissionCookie()) {
    return;
  }
  if (window.fbq) {
    const params = {
      content_ids: content_ids,
      num_items: num_items,
      value: `${price}`,
      currency: 'USD',
      content_type: 'destination',
    };
    const event_id = getEventId();
    const track_params = { eventID: event_id };
    if (authentication?.user?.user_id) track_params['external_id'] = authentication.user.user_id;
    window.fbq('track', 'InitiateCheckout', params, track_params);
    sendConversion({
      event_name: 'InitiateCheckout',
      params,
      additional_params: { event_id },
      token: authentication.token
    });
  }
  return false;
};

export const search = (data, authentication) => {
  if (getNoTrackerPermissionCookie()) {
    return;
  }
  if (window.fbq) {
    if (data.activity_list) {
      let min_price = 0;
      let min_date = 0;
      let max_date = 0;
      let search_result = [];
      let counter = 0;
      data.activity_list.map((v, i) => {
        if (v.content_type == 'tour') {
          if (v.available == 1) {
            search_result.push(v.tour_idx + '');
            if (counter == 0 || v.price_usd <= min_price) {
              min_price = v.price_usd;
            }
            if (v.min_date && v.max_date) {
              let cur_min_date = parseInt(v.min_date.replace('-', ''));
              let cur_max_date = parseInt(v.max_date.replace('-', ''));
              if (min_date == 0 || (cur_min_date <= parseInt(min_date.toString().replace('-', '')) && cur_min_date > 0)) {
                min_date = v.min_date;
              }
              if (cur_max_date >= parseInt(max_date.toString().replace('-', '')) && cur_min_date > 0) {
                max_date = v.max_date;
              }
            }
            counter++;
          }
        }
      });
      let search_city = data.city_name;
      if (!search_city || data.world_code == 1000000000) {
        search_city = 'Seoul';
      }
      let search_country = data.country_name;
      if (!search_country) {
        search_country = 'South Korea';
      }
      let params = {
        content_type: 'destination',
        content_ids: search_result,
        value: min_price,
        currency: 'USD',
        city: search_city,
        region: search_city,
        country: search_country,
      };
      if (min_date) {
        params.travel_start = min_date;
      }
      if (max_date) {
        params.travel_end = max_date;
      }
      if (data.keyword && search_result.length && min_price > 0) {
        const event_id = getEventId();
        const track_params = { eventID: event_id };
        if (authentication?.user?.user_id) track_params['external_id'] = authentication.user.user_id;
        window.fbq('track', 'Search', params, track_params);
        sendConversion({
          event_name: 'Search',
          params,
          additional_params: {
            event_id
          },
          token: authentication.token
        });
      }
    }
  }
};

const sendConversion = (props) => {
  const { event_name, params, additional_params, token } = props;
  const data = {
    custom_data: params
  };
  if (additional_params) {
    data['additional_data'] = additional_params;
  }
  const url = window.location.href;
  let user_data = {};
  let authentication_header = {};
  if (token) {
    authentication_header = { headers: { Authorization: 'bearer ' + token }};
  } else if (!additional_params) {
    let properties = getDecryptedCookie('properties');
    if (properties) {
      properties = JSON.parse(properties);
      if (properties) {
        user_data['first_name'] = properties.first_name || '';
        user_data['last_name'] = properties.last_name || '';
        user_data['email'] = properties.email || '';
        if (properties.country_dialcode && properties.phone_number) {
          let formatted_dial_code = (properties.country_dialcode.split('_')[1]).replace(/\D/g,'');
          let formatted_phone_number = (properties.phone_number.replace(/^0+/, '')).replace(/\D/g,'');
          if (formatted_dial_code && formatted_phone_number) {
            user_data['phone'] = formatted_dial_code + formatted_phone_number;
          }
        }
        if (properties.nationality) {
          user_data['nationality_idx'] = properties.nationality;
        }
      }
    }
  }
  let country_code = getCookie('tr_location');
  if (country_code) {
    user_data['country_code'] = country_code;
  }
  const fbc = getCookie('_fbc');
  if (fbc) {
    user_data['fbc'] = fbc;
  }
  const fbp = getCookie('_fbp');
  if (fbc) {
    user_data['fbp'] = fbp;
  }
  if (Object.keys(user_data).length > 0) {
    data["user_data"] = user_data;
  }
  axios
    .post(
      `${publicRuntimeConfig.API_PATH}/analytics/conversion`,
      {event_name, data, url},
      authentication_header
    );
};